
import { Component, Emit, Inject, Model, Prop, Provide, Vue, Watch } from 'vue-property-decorator';
import MessageContentWrap from '@/common/vue/data/content/impl/message/MessageContentWrap';
import app from '@/app/App';
import FileDownload from '@/app/com/main/component/FileDownload';
import Section from '@/app/com/common/chat/Section';
import MessageContentItem from '@/views/common/message/MessageContentItem.vue';
import DocumentUtil from '@/common/web/util/DocumentUtil';


@Component({
    components: {
        MessageContentItem,
    },
})
export default class ContentPane extends Vue {

    get hasSections() {
        const data = this.data;
        // console.log('datda', data)

        const has = (data && data.content && data.content.sections && data.content.sections.length > 0);
        return has;
    }
    @Prop({
        type: MessageContentWrap,
        required: false,
        default: () => (new MessageContentWrap()),
    })
    private data!: MessageContentWrap;

    public appendElement(html: string) {
        const contentPaneName = 'contentPane';
        const contentPane = this.$refs[contentPaneName];
        if (contentPane) {
            DocumentUtil.appendElement(contentPane as Element, html);
        }
    }

    private download(url: string) {
        const fileDownload: FileDownload = app.appContext.getMaterial(FileDownload);
        fileDownload.download(url);
    }

    private contentClick(e: Event) {
        const n = e.target;
        if (n instanceof Element) {

            const node = (n as Element);
            // 获取触发事件对象的属性
            const nodeName = node.nodeName.toLocaleLowerCase();
            if ('button' === nodeName) {
                const url = node.getAttribute('file-url');
                if (url) {
                    this.download(url);
                }
            }
        }
    }

    private resend() {
        const data = this.data;
        if (typeof data.resend === 'function') {
            this.data.resend(this.data.content);
        }
    }

    private hasItems(section: Section) {
        const has = (section && section.items && section.items.length > 0);
        return has;
    }

    private getText(value: string) {
        return 'text';
    }
}
