
    import {Component, Emit, Inject, Model, Prop, Provide, Vue, Watch} from 'vue-property-decorator';
    import AtValue from '@/app/com/common/chat/item/AtValue';

    @Component({
        components: {},
    })
    export default class ContentItemAt extends Vue {
        @Prop({
            type: Object,
            required: false,
            default: () => ({}),
        })
        private data!: AtValue;
    }
