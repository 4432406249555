
    import {Component, Emit, Inject, Model, Prop, Provide, Vue, Watch} from 'vue-property-decorator';
    import ImageValue from '@/app/com/common/chat/item/ImageValue';

    @Component({
        components: {},
    })
    export default class ContentItemImage extends Vue {
        @Prop({
            type: Object,
            required: false,
            default: () => ({}),
        })
        private data!: any | ImageValue;
    }
