
import { Component, Emit, Inject, Model, Prop, Provide, Vue, Watch } from 'vue-property-decorator';
import app from '@/app/App';
import FaceBox from '@/app/com/main/module/support/face/box/FaceBox';
import FaceCategory from '@/app/com/main/module/support/face/data/FaceCategory';
import FaceItem from '@/app/com/main/module/support/face/data/FaceItem';

@Component({
    components: {},
})
export default class FacePane extends Vue {

    private tab = '';
    private tabs: Array<{ name: string, key: string }> = [];
    private items: FaceCategory[] = [];

    public created() {
        this.initializeEvent();
    }

    public mounted() {
        this.initializeData();
    }

    private initializeData() {
        const own = this;
        const faceBox: FaceBox = app.appContext.getMaterial(FaceBox);
        this.items = faceBox.getFaceCategories();
        const items = this.items;
        const tabs: Array<{ name: string, key: string }> = [];
        for (const v of items) {
            if (v.visible) {
                tabs.push({ name: v.name, key: v.id });
            }
        }
        this.tabs = tabs;
        if (tabs.length > 0 && this.tab === '') {
            this.tab = tabs[0].key;
        }
    }

    private initializeEvent() {
        const own = this;
    }

    private onFace(value: FaceItem) {
        this.selected(value);
    }

    private onTab(tab: string) {
        this.tab = tab;
    }

    private getImageSize(face: FaceItem) {
        const height = face.height;
        const width = face.width;
        if (height && width) {
            return {
                height: height + 'px',
                width: width + 'px',
            };
        } else {
            return {};
        }
    }

    private getWarpSize(face: FaceItem) {
        let height = face.height;
        let width = face.width;
        if (height && width) {
            height = height + 4;
            width = width + 4;
            return {
                height: height + 'px',
                width: width + 'px',
            };
        } else {
            return {};
        }
    }

    @Emit('on-selected')
    private selected(value: FaceItem) {
        // 选中
    }
}
