
import { Component, Emit, Inject, Model, Prop, Provide, Vue, Watch } from 'vue-property-decorator';
import PromptContentWrap from '@/common/vue/data/content/impl/prompt/PromptContentWrap';

@Component({
    components: {},
})
export default class PromptContentPane extends Vue {
    @Prop({
        type: PromptContentWrap,
        required: false,
        default: () => (new PromptContentWrap()),
    })
    private data!: PromptContentWrap;
}
