
import {Component, Emit, Inject, Model, Prop, Provide, Vue, Watch} from 'vue-property-decorator';
import ByteSizeUtil from '@/app/common/util/ByteSizeUtil';
import AudioValue from '@/app/com/common/chat/item/AudioValue';

@Component({
    components: {},
})
export default class ContentItemVideo extends Vue {
    @Prop({
        type: Object,
        required: false,
        default: () => ({}),
    })
    private data!: any | AudioValue;


    get sizeText(): string {
        const data = this.data;
        const size = data.size;
        return ByteSizeUtil.getSizeText(size);
    }
}
