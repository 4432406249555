
    import {Component, Emit, Inject, Model, Prop, Provide, Vue, Watch} from 'vue-property-decorator';

    @Component({
        components: {},
    })
    export default class ProcessBar extends Vue {
        /**
         * 进度条百分比
         */
        @Prop({
            type: Number,
            required: false,
            default: () => (0),
        })
        private progress!: number;

        @Prop({
            type: String,
            required: false,
            default: () => (''),
        })
        private speedText!: string;

        private mounted() {
            this.$nextTick(() => {
                //
            });
        }

        get percentage() {
            const w = this.progress + '%';
            return {width: w};
        }
    }
