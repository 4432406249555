
import { Component, Emit, Inject, Model, Prop, Provide, Vue, Watch } from 'vue-property-decorator';
import FileValue from '@/app/com/common/chat/item/FileValue';
import ProcessBar from '@/views/common/progress/ProcessBar.vue';
import App from '@/app/App';
import FileIconBox from '@/app/com/main/module/support/file/box/FileIconBox';
import FileNameUtil from '@/app/common/util/FileNameUtil';
import ByteSizeUtil from '@/app/common/util/ByteSizeUtil';
import FileDownloadDefineData from '@/app/com/client/module/file/FileDownloadDefineData';
import FileProgressInfo from '@/app/com/client/module/file/FileProgressInfo';
import Item from '@/app/com/common/chat/Item';
import FileExtendInfo from '@/app/com/client/module/file/FileExtendInfo';

@Component({
    components: {
        ProcessBar,
    },
})
export default class ContentItemFile extends Vue {
    @Prop({
        type: Object,
        required: false,
        default: () => ({}),
    })
    private data!: any | FileValue;

    @Prop({
        type: Item,
        required: false,
        default: () => (new Item()),
    })
    private item!: Item;
    private isAndroid: boolean = false;
    private isIOS: boolean = false;

    private fileDownloadingInfo: FileProgressInfo = new FileProgressInfo();

    public mounted() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        if (flag) {
            let u = navigator.userAgent;
            this.isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;   //判断是否是 android终端
            this.isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);     //判断是否是 iOS终端
        } else {
            this.isAndroid = true;
        }
        const item = this.item;
        if (item.data && item.data instanceof FileExtendInfo) {
            const fileExtendInfo: FileExtendInfo = item.data;
            this.fileDownloadingInfo = fileExtendInfo.progressInfo;
        } else {
            const fileExtendInfo: FileExtendInfo = new FileExtendInfo();
            fileExtendInfo.progressInfo = this.fileDownloadingInfo;
            item.data = fileExtendInfo;
        }
    }

    get icon(): string {
        const data = this.data;
        const fileName = data.name;
        const extension = FileNameUtil.getSuffixName(fileName);
        const fileIconBox: FileIconBox = App.appContext.getMaterial(FileIconBox);
        const src = fileIconBox.getIcon(extension);
        return src;
    }

    get sizeText(): string {
        const data = this.data;
        const size = data.size;
        return ByteSizeUtil.getSizeText(size);
    }
    private copyText(url: string) {
        let inputDom = document.createElement('textarea') // js创建一个文本框
        document.body.appendChild(inputDom) //将文本框暂时创建到实例里面
        inputDom.value = url //将需要复制的内容赋值到创建的文本框中
        inputDom.select() //选中文本框中的内容
        inputDom.focus()
        document.execCommand('copy') //执行复制操作
        document.body.removeChild(inputDom) //最后移出
        alert('复制链接成功，请在浏览器打开下载文件')
    }
    private onDownload() {
        // :href="data.url" target="_blank" href="javascript:void(0)"
        const data = this.data;
        const url = data.url;
        const fileName = data.name;
        const size = data.size;
        const fileDownloadingInfo = this.fileDownloadingInfo;

        const fd: FileDownloadDefineData = App.appContext.getMaterial(FileDownloadDefineData);
        if (this.isAndroid) {
            window.open(url)
        } else {
            // window.location.href = url;
            const a = document.createElement('a');
            // 将url转成blob地址。如果url是一个完整的链接，需要后端开清跨域请求
            fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
                alert(JSON.stringify(blob))
                a.href = URL.createObjectURL(blob)
                a.download = fileName // 下载文件的名字
                document.body.appendChild(a)
                a.click()
            })
        }

        // let link: any = document.querySelectorAll('.downloadLink')[0];
        // link.click();


        // const a = document.createElement('a');
        // // 将url转成blob地址。如果url是一个完整的链接，需要后端开清跨域请求
        // fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
        //         alert(JSON.stringify(blob))
        //         a.href = URL.createObjectURL(blob)
        //         a.download = filename // 下载文件的名字
        //         document.body.appendChild(a)
        //         a.click()
        //     })

        // let blob = new Blob([data]); // 原 此种方式在pc端可以下载，但是手机端不行
        // let blob = new Blob([url]); //
        // const blobUrl = window.URL.createObjectURL(blob);
        // // alert(JSON.stringify(blobUrl))
        // console.log(data)
        // console.log(blobUrl)
        // const a = document.createElement('a');
        // a.style.display = 'none';
        // a.href = url;
        // // a.download = fileName;
        // a.setAttribute('download', fileName);
        // document.body.appendChild(a);
        // a.click();

        // fd.download(url, fileName, size, fileDownloadingInfo);
        // fd.download(url, fileName, size);
        // DownloadToolUtil.tool.download(url, fileName, (e) => {
        //     downloadData.isDownload = true;
        //     downloadData.progress = ByteSizeUtil.getPercentageIntegerRate(size, e.total);
        // });
    }
}
